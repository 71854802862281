.blueButton {
    color: #FFFFFF;
    background: rgb(42, 88, 143);
    border-radius: 100px;
    padding: 7px 16px;
}

.whiteButton {
    color: rgb(42, 88, 143);
    background: #FFFFFF;
    border-radius: 100px;
    padding: 7px 16px;
    border: 1px solid rgb(42, 88, 143);
}

.redButton {
    color: #D32F2F;
    background: #FFFFFF;
    border-radius: 100px;
    padding: 7px 16px;
    border: 1px solid #D32F2F;
}

.greenButton {
    color: #fff;
    background: #01963a;
    border-radius: 100px;
    padding: 7px 16px;
    border: 1px solid rgb(42, 88, 143);
}