.autehenticationLinksBlock {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 24px;
}

.registrationConditionsBlock {
    margin-top: 24px;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.4px;
}

.autehenticationLink {
    font-weight: 500;
    font-size: 13px;
    line-height: 14px;
    margin: 0 8px;
}

.autehenticationSubLink {
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.4px;
    text-decoration: underline;
}

.sendCodeAgain {
    font-weight: 500;
    font-size: 13px;
    line-height: 14px;
    text-align: center;
}