.customModal {
    width: fit-content !important;
    min-width: 320px !important;
}

.customModal .ant-modal-content {
    padding: 24px;
    background: #FFFFFF;
    border-radius: 8px;
}

.customModal .ant-modal-content .ant-modal-footer {
    display: none;
}

.customModal .ant-modal-content .ant-modal-body {
    padding: 0;
}