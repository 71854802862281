.authenticationBlock {
    padding: 20px;
    background: #FFFFFF;
    border-radius: 8px;
    min-width: 364px;
    margin: 16px 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 10px;
}