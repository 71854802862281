.pagination {
    display: flex;
    align-items: center;
    margin-top: 24px;
}

.pagination img {
    cursor: pointer;
}

.paginationItem {
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.paginationItem.active {
    background: #F4F4F4;
    border-radius: 100px;
    margin: 0 2px;
}