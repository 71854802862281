@media (max-width: 575.98px) {
    .twoColumnForm {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }

    .twoColumnForm_column {
        width: 100% !important;
    }

    .contentBlock {
        padding: 15px;
    }

    .contentBlock_header,
    .contentBlock_header__leftHalf,
    .contentBlock_header__rightHalf {
        flex-direction: column;
        align-items: center;

    }

    .contentBlock_header__leftHalf .search {
        margin-left: 0;
        margin-top: 16px;
    }

    .contentBlock_header__rightHalf .filter {
        margin-right: 0;
        margin-top: 16px;
        margin-bottom: 16px;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .twoColumnForm {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }

    .twoColumnForm_column {
        width: 100% !important;
    }

    .contentBlock_header,
    .contentBlock_header__leftHalf,
    .contentBlock_header__rightHalf {
        flex-direction: column;
        align-items: center;

    }

    .contentBlock_header__leftHalf .search {
        margin-left: 0;
        margin-top: 16px;
    }

    .contentBlock_header__rightHalf .filter {
        margin-right: 0;
        margin-top: 16px;
        margin-bottom: 16px;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .twoColumnForm {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }

    .twoColumnForm_column {
        width: 100% !important;
    }

    .contentBlock_header__leftHalf,
    .contentBlock_header__rightHalf {
        flex-direction: column-reverse;
    }

    .contentBlock_header__leftHalf {
        align-items: flex-start;
    }

    .contentBlock_header__leftHalf .search {
        margin-left: 0;
        margin-bottom: 16px;
    }

    .contentBlock_header__rightHalf {
        align-items: flex-end;
    }

    .contentBlock_header__rightHalf .filter {
        margin-right: 0;
        margin-top: 16px;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {}

@media (min-width: 1200px) {}