.tableBlock {
    overflow-x: scroll;
}

.tableBlock::-webkit-scrollbar {
    width: 5px;
    height: 2px;
}

.tableBlock::-webkit-scrollbar-track {
    background: white;
}

.tableBlock::-webkit-scrollbar-thumb {
    background-color: grey;
    border-radius: 20px;
}

.customTable_header {
    border: 1px solid #E4E4E4;
    border-radius: 8px;
    background: #FFFFFF;
    padding: 15px;
    width: 100%;
    display: flex
}

.customTable_header__item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.customTable_header__item img {
    cursor: pointer;
    margin-left: 8px;
}

.customTable_body {
    border: 1px solid #E4E4E4;
    border-radius: 8px;
    background: #FFFFFF;
    margin-top: 16px;
}

.customTable_body__row {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 15px;
}

.customTable_body__row p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 8px;
}

.customTable_body__notFound {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    padding: 50px;
}