.verificationImageBlock {
    width: 30vw;
    margin: auto;
    display: flex;
    justify-content: flex-start;
    gap: 15px;
    flex-wrap: wrap;
}

.verificationImage {
    width: 30% !important;
}

@media (max-width: 575.98px) {
    .verificationImageBlock {
        width: 100%;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .verificationImageBlock {
        width: 100%;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .verificationImageBlock {
        width: 100%;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {}

@media (min-width: 1200px) {}