/*Main text style*/
.mainText {
    color: #0E1412;
}

/*Subtext style*/
.subtext {
    color: #525252;
}

.messageDate {
    color: #6F6F6F;
}

/*Active text style*/
.activeText {
    color: rgb(42, 88, 143);
}

.activeText:hover {
    color: rgb(42, 88, 143);
}

/*If the button is not white*/
.whiteButtonText {
    color: #FFFFFF;
}

/* Text/Placeholder */
.placeholder {
    color: #A8A8A8;
}

/* Errors */
.errorColor {
    color: #D32F2F;
}

.blueText {
    color: #3F51B5;
}

.warnintText {
    color: #FF9800;
}