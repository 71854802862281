.tableScrollBlock {
    max-width: 1320px;
    max-height: 500px;
    overflow: scroll;
    margin-bottom: 12px;
}

.tableScrollBlock .ant-form-item {
    margin-bottom: 0 !important;
}

.tableScrollBlock::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

.tableScrollBlock::-webkit-scrollbar-track {
    background: rgba(128, 128, 128, 0.155);
    border-radius: 5px;
}

.tableScrollBlock::-webkit-scrollbar-thumb {
    background-color: rgba(128, 128, 128, 0.53);
    border-radius: 5px;
}