.infoBlock {
    border: 1px solid #E4E4E4;
    border-radius: 8px;
    background: #FFFFFF;
    padding: 24px;
    width: 100%;
}

.infoBlock.twoRow, .twoRow {
    display: flex;
    flex-direction: row;
}