.userLayout {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.userLayout_contentBlock {
    flex-grow: 3;
    display: flex;
    position: relative;
}

.outletLayout {
    width: calc(100% - 48px);
    margin-left: 48px;
}