.switch {
    border: 1px solid #E4E4E4;
    border-radius: 8px;
    display: flex;
}

.miniSwitch {
    width: fit-content;
}

.switch_item {
    width: 100%;
    text-align: center;
    padding: 15px 0;
    cursor: pointer;
    background: #F4F4F4;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
}

.miniSwitch .switch_item {
    padding: 7px 16px;
    width: auto;
}

.switch_item:nth-child(1) {
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
}

.switch_item:nth-last-child(1) {
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
}

.switch_item.active {
    background: #FFFFFF;
    border-bottom: 2px solid rgb(42, 88, 143);
}