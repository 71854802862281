.simpleButton {
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 7px 16px;
}

.twoButton {
    display: flex;
    align-items: center;
}

.twoButton_item {
    margin-left: 16px;
}

@media (max-width: 575.98px) {
    .twoButton {
        flex-direction: column;
    }

    .twoButton_item {
        margin-left: 0;
        margin-top: 16px;
    }
}