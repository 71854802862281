
.userProfile_header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    align-items: center;
}

.userProfile_header__info {
    display: flex;
    align-items: center;
}

.userProfile_header__info__avatar {
    width: 56px;
    height: 56px;
    background: #3F51B5;
    border-radius: 1000px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 24px;
    color: white;
}

.userProfile_header__info__avatar__photo {
    width: 56px;
    height: 56px;
    border-radius: 1000px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin-right: 16px;
}