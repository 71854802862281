.drawerMenu {
    box-shadow: inset -1px 0px 0px #E0E0E0;
    width: 48px;
    height: 100%;
    transition: 0.5s;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    justify-content: space-between;
    background: white;
    position: absolute;
    z-index: 1000;
}

.drawerMenuBg {
    position: absolute;
    width: 100%;
    background: rgba(14, 20, 18, 0.4);
    height: 100%;
    z-index: 100;
}

.drawerMenu_mainLinks,
.drawerMenu_subLinks {
    display: flex;
    flex-direction: column;
}

.drawerMenu.active {
    width: 240px;
    position: absolute;
    bottom: 0;
}

.drawerMenu_menuButton {
    cursor: pointer;
    margin: 11px 16px;
    width: 16px;
    height: 16px;
}

.drawerMenu_link {
    padding: 11px 16px;
}

.drawerMenu_link__item {
    display: flex;
    justify-content: flex-start;
}

.drawerMenu_link__item p {
    margin-left: 16px !important;
    white-space: nowrap;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
}

.drawerMenu_link__item svg {
    width: 16px;
    height: 16px;
}

.drawerMenu_subLinks__item {
    padding: 11px 16px;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    white-space: nowrap;
}